<template>
  <div class="internal">
    <div class="delete">
      <button @click="clear">Smazat data</button>
    </div>
    <label>
      <input type="checkbox" v-model="noForceFullscreen" />
      Nevynucuj fullscreen
    </label>
    <div>
      Error correction level:
      <select v-model="errorCorrectionLevel">
        <option>L</option>
        <option>M</option>
        <option>Q</option>
        <option>H</option>
      </select>
    </div>
    <div class="qr-codes" ref="codes">
      <div
        class="qr-code"
        v-for="screen in screens"
        :key="screen"
        :data-screen="screen"
      >
        <a href="#" @click="go(screen)">
          <div class="codeImage"></div>
          <div class="label">{{ screen }}</div>
        </a>
        <a href="#" @click.prevent="download" class="download-button">↓</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import dialogues from "../data/dialogues";
import QRCode from "qrcode-svg";

type ErrorCorrectionLevel = "L" | "M" | "H" | "Q";

export default defineComponent({
  name: "Internal",
  data: () => {
    return {
      errorCorrectionLevel: "H" as ErrorCorrectionLevel,
      noForceFullscreen: false,
    };
  },
  computed: {
    screens(): string[] {
      return Object.entries(dialogues)
        .filter(([k, d]) => d.start)
        .map(([k, d]) => k);
    },
  },
  mounted() {
    this.refresh();
    this.noForceFullscreen = this.$store.state.noForceFullscreen;
  },
  watch: {
    errorCorrectionLevel() {
      this.refresh();
    },
    noForceFullscreen() {
      this.$store.commit("setNoForceFullscreen", this.noForceFullscreen);
    },
  },
  methods: {
    go(screen: string) {
      let done = false;
      Object.entries(dialogues).forEach(([key, dialogue]) => {
        if (!done) {
          if (key == screen) {
            done = true;
            this.$router.push(`/${screen}`);
          } else {
            console.log(`simulating finish of ${key}`);
            this.$store.commit("addFinishedAudio", key);
            (dialogue.items || []).forEach((item) => {
              this.$store.commit("addUnlockedItem", item);
            });
          }
        }
      });
    },
    download(ev: MouseEvent) {
      const codeEl = (ev.target as HTMLElement).parentElement;
      const qrEl = codeEl?.querySelector(".codeImage svg");
      if (!qrEl) {
        throw new Error("could not find qr code element");
      }
      const base64doc = btoa(unescape(encodeURIComponent(qrEl.outerHTML)));
      const a = document.createElement("a");
      const e = new MouseEvent("click");

      a.download = `${codeEl?.dataset["screen"]}.svg`;
      a.href = "data:text/html;base64," + base64doc;
      a.dispatchEvent(e);
    },
    clear() {
      this.$store.commit("clear");
      this.$router.push("/").then(() => {
        window.location.reload();
      });
    },
    refresh() {
      const codes = this.$refs.codes as HTMLDivElement;

      (Array.from(codes.querySelectorAll(".qr-code")) as HTMLElement[])
        .filter((el) => el.dataset.screen)
        .forEach((el) => {
          const data = `https://audiowalk-leti.cz/#/${el.dataset.screen}`;
          const qr = new QRCode({
            content: data,
            padding: 4,
            width: 256,
            height: 256,
            join: true, // ?
            color: "#000000",
            background: "#ffffff",
            ecl: this.errorCorrectionLevel,
          });
          el.querySelector(".codeImage")!.innerHTML = qr.svg();
          const img = el.querySelector("img");
          if (img) {
            img.title = img.alt = data;
          }
        });
    },
  },
});
</script>

<style lang="scss">
.internal {
  font-family: monospace;

  padding: 2rem;

  button {
    font-weight: bold;
    background: white;
    border: 1px solid red;
    font-size: 24pt;
    padding: 0.5em;
    margin: 0.5em 0;
  }
}

.qr-codes {
  display: flex;
  flex-wrap: wrap;
}

.qr-code {
  margin: 1em;
  border: 1px solid black;
  text-align: center;

  img {
    width: 256px;
    height: 256px;
  }

  .label {
    display: block;
    margin: 0.5em 0;
    font-weight: bold;
    font-size: 20pt;
  }

  a {
    text-decoration: none;
  }
}

.download-button {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-bottom: 0.5em;

  font-size: 20pt;
  font-weight: bold;
  line-height: 1;

  color: black;
  background: white;

  border: 2px solid black;
  border-radius: 1em;
}

@media print {
  #audiowalk {
    background: white;
  }

  .internal > *,
  .download-button {
    display: none;
  }

  .internal a {
    color: black;
  }

  .qr-codes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
